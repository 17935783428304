<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { TruckIcon, GlobeEuropeAfricaIcon, LockClosedIcon } from '@heroicons/vue/24/outline'

const { t } = useI18n()
</script>

<template>
    <section class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="flex items-center py-4 divide-x sm:justify-center divide-mint-200 bg-mint-50 lg:py-8 rounded-xl text-p1">
            <div class="w-full sm:w-1/2 lg:w-1/3">
                <div class="flex items-center px-4 mx-auto sm:px-6 lg:px-8">
                    <TruckIcon
                        class="flex-shrink-0 w-8 h-8 text-mint-600 stroke-[1.4]"
                        aria-hidden="true"
                    />

                    <div class="flex flex-col-reverse flex-auto ml-4">
                        <div class="font-medium text-mint-900">{{ t('pages.home.features.free-shipping.title') }}</div>
                        <p class="text-mint-800 text-p2">{{ t('pages.home.features.free-shipping.description', { amount: `${$page.props.cart.free_shipping_threshold} €` }) }}</p>
                    </div>
                </div>
            </div>

            <div class="hidden sm:w-1/2 lg:w-1/3 sm:block">
                <div class="flex items-center px-4 mx-auto sm:px-6 lg:px-8">
                    <GlobeEuropeAfricaIcon
                        class="flex-shrink-0 w-8 h-8 text-mint-600 stroke-[1.4]"
                        aria-hidden="true"
                    />

                    <div class="flex flex-col-reverse flex-auto ml-4">
                        <div class="font-medium text-mint-900">{{ t('pages.home.features.baltic-shipping.title') }}</div>
                        <p class="text-mint-800 text-p2">{{ t('pages.home.features.baltic-shipping.description') }}</p>
                    </div>
                </div>
            </div>

            <div class="flex-none hidden lg:w-1/3 lg:block">
                <div class="flex items-center px-4 mx-auto sm:px-6 lg:px-8">
                    <LockClosedIcon
                        class="flex-shrink-0 w-8 h-8 text-mint-600 stroke-[1.4]"
                        aria-hidden="true"
                    />

                    <div class="flex flex-col-reverse flex-auto ml-4">
                        <div class="font-medium text-mint-900">{{ t('pages.home.features.secure-shopping.title') }}</div>
                        <p class="text-mint-800 text-p2">{{ t('pages.home.features.secure-shopping.description') }}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
